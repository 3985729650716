.CoursePerfomanceHeader {
  @apply flex w-full flex-row items-center justify-between gap-2 p-2 md:gap-6 md:p-4;
  @apply rounded-[30px] bg-white shadow-card shadow-shadow-700;

  &__course {
    @apply flex w-7/12 items-center gap-3 md:w-full md:gap-4;
    & > div {
      @apply overflow-hidden;
    }
    h3 {
      @apply text-sm font-bold text-secondary-grey-900 md:text-lg;
      @apply overflow-hidden text-ellipsis whitespace-nowrap;
    }
    span {
      @apply text-xs font-medium text-secondary-dark-grey-500 md:text-base;
      @apply overflow-hidden text-ellipsis whitespace-nowrap;
    }
  }

  &__buttons {
    @apply flex gap-2 md:gap-4;
  }
}
