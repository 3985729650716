.CoursePerfomanceDaily {
  @apply flex w-full flex-col gap-4 self-stretch rounded-3xl p-4 md:rounded-[30px] md:p-6;
  @apply bg-white shadow-card shadow-shadow-600;
  @apply text-xs font-medium text-secondary-dark-grey-500 md:text-sm;

  &__head {
    @apply flex flex-row items-start justify-between;
  }

  h2 {
    @apply flex items-center gap-2 text-2xl font-semibold text-secondary-dark-grey-900 md:text-[32px] md-max:p-0;
  }

  span {
    @apply text-[10px] text-secondary-dark-grey-500 md:text-xs;
  }
}
