input.defaultCheckbox {
  @apply grid items-center justify-center border-secondary-grey-500 text-white;
}

input.defaultCheckbox::before {
  content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.66662 10.115L12.7946 3.98633L13.7379 4.92899L6.66662 12.0003L2.42395 7.75766L3.36662 6.81499L6.66662 10.115Z' fill='white'/%3E%3C/svg%3E%0A");
  fill: currentColor;
  opacity: 0;
}

input.defaultCheckbox::before path {
  fill: currentColor;
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

input.defaultCheckbox.radioCheck::before {
  content: '';
  width: 8px;
  height: 8px;
  background: #4318ff;
  border-radius: 4px;
  position: absolute;
  margin: 0 auto;
  opacity: 0;
  right: 0;
  left: 0;
}

input:checked.defaultCheckbox.radioCheck::before {
  opacity: 1;
}

input:checked.defaultCheckbox.radioCheck-lg::before {
  opacity: 1;
}

input.defaultCheckbox.radioCheck-lg::before {
  width: 10px;
  height: 10px;
  background: #4318ff;
  content: '';
  border-radius: 5px;
  position: absolute;
  opacity: 0;
  margin-top: 4px;
}
