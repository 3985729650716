.FeedCard {
  @apply flex flex-col gap-4 rounded-3xl bg-white p-2 shadow-card shadow-shadow-700 md:gap-6 md:rounded-[30px] md:p-6;

  &__wrapper {
    @apply flex flex-col gap-4;
  }

  &__head {
    @apply flex items-start justify-between gap-4 md:items-center;
  }

  &__user {
    @apply flex items-center gap-4;
    @apply text-sm font-medium capitalize text-secondary-dark-grey-500 md:text-base;

    h5 {
      @apply text-base font-bold text-secondary-grey-900 md:text-lg;
    }

    &-img {
      @apply flex h-10 w-10 items-center justify-center rounded-full md:h-[46px] md:w-[46px] md:p-0;
      img {
        @apply h-full w-full rounded-full object-cover;
      }
    }
  }

  &__body {
    @apply relative flex flex-col gap-2 overflow-hidden break-words text-base font-medium text-secondary-dark-grey-900;
    h3 {
      @apply self-stretch overflow-hidden overflow-ellipsis text-lg font-bold text-secondary-dark-grey-900;
    }
    button {
      @apply absolute bottom-0 right-0 bg-primary-white pl-3 text-secondary-dark-grey-500;
    }
  }
}
