.AuthLayout {
  @apply flex w-full flex-row items-center justify-center sm-max:p-3.5 lg:justify-between;

  // TODO: Value is hardcoded cuz in order to expand AuthLayout to full height
  // we need to re-make entire app layout

  // HACK: There is bug with Tailwind, if last rule is custom value then it throws error at build
  // for that reason added flex at the end

  @apply min-h-[calc(100dvh-68px-16px-56px-96px)] flex;
  @apply sm:min-h-[calc(100dvh-74px-32px-56px-96px)] flex;
  @apply md:min-h-[calc(100dvh-90px-32px-83px-96px)] flex;
  @apply lg:min-h-[calc(100dvh-90px-32px-83px)] flex;

  &__title {
    @apply mb-8 flex flex-col gap-2 sm-max:mb-4;
    h1 {
      @apply text-[32px] leading-[36px] text-secondary-dark-grey-900 sm-max:text-2xl sm-max:leading-[28px] font-semibold;
    }
    span {
      @apply text-base text-secondary-dark-grey-500 sm-max:text-sm;
    }
  }

  &__wrapper {
    @apply z-10 flex w-full flex-col;
    @apply rounded-3xl bg-white p-4 shadow-card shadow-shadow-700 sm:mx-auto sm:w-[512px] sm:max-w-lg sm:rounded-[30px] sm:p-11;
  }

  &__bg {
    @apply fixed left-0 top-0 z-0 h-1/2 w-full rounded-bl-3xl rounded-br-3xl object-fill md:rounded-bl-[30px] md:rounded-br-[30px] md:object-fill;
  }
}
