@import 'src/styles/varibles';

.b-dad {
  label {
    width: 100%;
  }

  &__area {
    @apply h-auto md:h-60;
    background-color: #fafcfe;
    border: 1px dashed #e0e5f2;
    min-width: 100%;
    max-width: 100%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 10px;

    span {
      color: #122693;
    }

    &__shadow-text {
      @apply self-stretch text-center text-[10px] font-medium text-secondary-dark-grey-500 md:text-xs;
    }

    svg {
      @apply h-10 w-10 md:h-auto md:w-auto;
    }

    &__text {
      @apply self-stretch text-center text-xs font-semibold text-secondary-dark-grey-900 md:text-base;
      margin-top: 20px;
      margin-bottom: 7px;
    }
  }

  &__shadow-text {
    font-size: 12px;
    font-weight: 500;
    color: #676767;
  }

  &__text {
    font-size: 18px;
    font-weight: 500;
    color: $black;
    margin-bottom: 4px;
    margin-top: 10px;
  }

  &__list {
    &__item {
      padding-bottom: 10px;
      padding-top: 10px;
      border-bottom: 1px solid $gray-300;

      &__text {
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 500;

        p {
          margin-bottom: 0;
        }
      }

      &__drag {
        cursor: pointer;
      }

      &__trash {
        max-width: 19px;
        min-width: 19px;
        cursor: pointer;
      }
    }
  }
}
