.AsideMenu {
  @apply flex flex-col items-start gap-2 self-stretch rounded-[30px] bg-white p-2 shadow-card shadow-shadow-700 md:p-4;

  &__item {
    @apply flex cursor-pointer items-center gap-2 self-stretch rounded-2xl px-3 py-2 text-base font-semibold text-secondary-dark-grey-700;
    &.active,
    &:hover {
      @apply bg-secondary-grey-300 font-bold text-secondary-dark-grey-900;
    }
  }
}
