.FavoritePostModal {
  @apply flex flex-col items-end gap-5;

  &__item {
    @apply flex w-full cursor-pointer items-center gap-4 rounded-2xl;

    h3 {
      @apply flex-[1_0_0] text-lg font-semibold;
    }

    svg {
      @apply fill-primary-purple-blue-500;
    }

    &.active,
    &:hover {
      @apply bg-secondary-grey-100;
    }
  }

  .selected {
    @apply mr-4 h-[34px] w-[34px] rounded-full bg-primary-purple-blue-100 p-2;
  }

  &__img {
    @apply flex h-[78px] w-[78px] flex-shrink-0 items-center justify-center rounded-2xl bg-secondary-grey-300;
  }

  &__btns {
    @apply flex items-center justify-between gap-4 self-stretch;
    button {
      @apply w-full;
    }
  }
}
