.m-form-select {
  appearance: none; /* Убрать кастомные стрелки на iOS */
  background: none;
}

.react-select__control {
  border-radius: 12px;
  border: 2px solid #ccc;
  min-height: 42px;

  &:hover {
    border-color: #aaa;
  }
}

.react-select__value-container {
  padding: 0 8px;
}

.react-select__single-value {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.react-select__placeholder {
  color: #888;
}

.react-select__menu {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.react-select__option {
  padding: 8px 12px;
  font-size: 14px;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator {
  color: #888;
}
