.ProfileCard {
  &.edit {
    @apply gap-[56px] p-3;
  }

  &__avatar {
    @apply relative m-auto flex items-center justify-center rounded-full lg:m-0;
    @apply h-[86px] w-[86px] lg:h-[66px] lg:w-[66px] lg:p-0;
  }

  &__edit {
    @apply absolute -bottom-1 -right-1 z-10 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-primary-purple-blue-500 lg:-bottom-2 lg:-right-2 lg:h-7 lg:w-7;
    svg {
      @apply h-2 w-2 lg:h-3 lg:w-3;
    }
  }

  &__user {
    @apply flex flex-col items-center text-xs text-secondary-dark-grey-500 lg:text-base;
    h1 {
      @apply text-lg font-bold text-secondary-grey-900 lg:text-3xl;
    }
    h1,
    p {
      @apply max-w-full truncate;
    }
  }
}
