.pagination {
  @apply flex items-center gap-2 md:gap-4;
  span,
  button {
    @apply flex h-[34px] w-[37px] items-center justify-center gap-1.5 rounded-full bg-white px-3.5 py-2 shadow shadow-shadow-700 md:rounded-xl;
    @apply text-center text-sm font-semibold text-primary-purple-blue-300;
  }
  button {
    @apply cursor-pointer;
    &:hover,
    &.active {
      @apply bg-primary-purple-blue-100 text-primary-purple-blue-500;
    }
  }

  &.rounded {
    button {
      @apply rounded-full;
    }
  }

  .prev,
  .next {
    @apply fill-secondary-dark-grey-500 p-3 hover:fill-primary-purple-blue-500 md:h-[42px] md:w-[42px] md:p-3;
  }
}
