.CourseRating {
  @apply flex gap-2 md:gap-6;

  &__total {
    @apply flex flex-col items-center justify-between text-[40px] font-bold text-primary-orange-500 md:text-7xl md:font-semibold;
    span {
      @apply text-xs font-semibold text-secondary-dark-grey-500 md:text-base md:font-bold;
    }
  }

  &__bar {
    @apply flex w-full flex-col gap-5 pb-1 pt-2;
  }

  &__stars {
    @apply flex flex-col justify-between gap-1 text-sm text-secondary-grey-900;
    &-star {
      @apply flex items-start justify-between gap-2 md:gap-3;
    }
  }
}
