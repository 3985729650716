.GradeCard {
  @apply flex cursor-pointer items-center justify-between gap-1 self-stretch rounded-2xl px-3 py-2 text-secondary-dark-grey-500 md:gap-4 md:p-3;
  @apply relative bg-secondary-grey-200 text-xs hover:bg-secondary-grey-400 md:text-sm;

  &.enrolled {
    @apply bg-primary-green-100 text-primary-green-500;
    h4 {
      @apply text-primary-green-900;
    }
    svg {
      @apply fill-primary-green-500;
    }

    & + .active {
      &::after {
        @apply bg-primary-purple-blue-400;
      }
    }

    & + .enrolled {
      &::after {
        @apply bg-primary-green-300;
      }
    }
  }

  &.active {
    @apply bg-primary-purple-blue-400 text-white;
    h4 {
      @apply text-white;
    }

    &.enrolled {
      @apply bg-primary-green-600 text-white;
      h4 {
        @apply text-white;
      }

      svg {
        @apply fill-primary-white;
      }
    }
  }

  h4 {
    @apply self-stretch text-base font-semibold text-secondary-dark-grey-900 md:text-base;
  }

  span {
    @apply text-nowrap font-semibold opacity-60;
  }

  svg {
    @apply fill-primary-white;
  }

  &:first-child {
    &::after {
      @apply hidden;
    }
  }

  &::after {
    content: '';
    @apply absolute -top-6 left-1/2 -z-[1] block h-6 w-[1px] bg-secondary-grey-500;
  }
}
