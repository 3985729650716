.EditRoleModal {
  @apply flex flex-col gap-3 md:gap-5;

  &__wrapper {
    @apply flex flex-col items-end gap-2 self-stretch md:gap-3;
    @apply text-xs font-medium text-secondary-dark-grey-500;
    h4 {
      @apply self-stretch overflow-hidden overflow-ellipsis text-sm font-semibold text-secondary-grey-900;
    }
  }
}
