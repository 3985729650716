.AsideCard {
  @apply flex w-full flex-col gap-4 rounded-2xl bg-white p-3 shadow-card shadow-shadow-700 md:rounded-[30px] md:p-4;

  &__head {
    @apply flex flex-wrap items-center justify-between gap-2;

    button {
      @apply ml-auto shrink-0;
    }
  }

  h4 {
    @apply text-base font-semibold;
  }
}
