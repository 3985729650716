.DatePicker {
  @apply flex w-auto items-center justify-between gap-1 px-4 py-2;
  @apply rounded-full bg-primary-purple-blue-100 font-montserrat text-sm font-semibold text-primary-purple-blue-500;

  input {
    @apply w-24 bg-primary-purple-blue-100 outline-none;
  }

  .datepicker-calendar {
    @apply grid p-0 md:flex;
    @apply rounded-[20px] border border-solid border-[#E2E8F0] bg-white text-secondary-dark-grey-900;

    .chakra-stack {
      @apply border-none;

      .chakra-button {
        @apply rounded-md px-3 py-2;
        &[aria-pressed='true'],
        &.css-38r9p9,
        &.css-cxf5u0 {
          @apply bg-primary-purple-blue-200;
        }
      }

      .chakra-divider {
        @apply border-b border-solid border-[#E2E8F0] p-0;
      }

      &:last-child {
        @apply sm-max:hidden;
      }
    }
  }
  // .chakra-popover__popper {
  //   @media screen and (max-width: 600px) {
  //     transform: translate(0, -50px) !important;
  //   }
  //   transform: translate(0, -70px) !important;
  //   inset: auto 10px 10px auto !important;
  // }
}
