@import '../../../styles/varibles';

.b-course-list {
  @apply flex w-full flex-col gap-2 md:gap-6;

  .form-control:focus {
    box-shadow: none !important;
    border-color: $gray-300 !important;
  }

  .form-control {
    border-left: 0;
  }

  .input-group-text {
    background-color: transparent;
    padding-right: 0;
  }

  .subtitle {
    @apply self-stretch text-base font-semibold text-secondary-grey-800 md:text-2xl md:font-bold;
  }
}
