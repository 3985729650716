.AvatarTitleTextCard {
  @apply flex items-start justify-between gap-3;
  @apply text-xs font-medium text-secondary-dark-grey-500 md:text-sm;

  &__img {
    @apply mt-1 h-[34px] w-[34px] flex-none overflow-hidden rounded-full md:h-10 md:w-10;
    img {
      @apply block h-full w-full object-cover;
    }
  }

  &__content {
    @apply flex w-full flex-col gap-0 overflow-hidden md:gap-0.5;
    h6 {
      @apply text-sm font-bold text-secondary-grey-900 md:text-base;
      @apply overflow-hidden text-ellipsis text-nowrap;
    }
    div {
      @apply flex flex-wrap gap-1 md:gap-1.5;
    }
  }

  &__children {
    @apply col-span-1;
    @apply flex-none;
  }
}
