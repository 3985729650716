.SchoolCard {
  @apply flex w-full items-start gap-3 rounded-3xl border border-secondary-dark-grey-500 bg-white p-2 md:items-center md:p-3;
  @apply relative self-stretch text-xs font-medium text-black-10 md:text-base;

  &.selected {
    @apply border-primary-purple-blue-500;

    & + .selected {
      &::after {
        @apply bg-primary-purple-blue-500;
      }
    }
  }

  h4 {
    @apply text-center text-base font-bold text-primary-purple-blue-500 md:text-lg;
  }

  span {
    @apply font-semibold lowercase text-secondary-dark-grey-500;
  }

  &__content {
    @apply flex flex-[1_0_0] cursor-pointer flex-col items-start justify-center gap-1 self-stretch rounded-2xl bg-secondary-grey-100 px-3 py-1.5 text-black-10 md:p-3;
    @apply hover:bg-secondary-grey-300;
  }

  &__checkbox {
    @apply flex cursor-pointer items-center justify-center gap-2 rounded-full border-2 border-primary-purple-blue-300 p-2 md:self-stretch md:rounded-2xl md:p-4;

    svg {
      @apply fill-primary-purple-blue-300;
    }

    &:hover {
      @apply border-primary-purple-blue-500;
      svg {
        @apply fill-primary-purple-blue-500;
      }
    }

    &.selected,
    &.purchased {
      @apply border-primary-purple-blue-500 bg-primary-purple-blue-500;
      svg {
        @apply fill-white;
      }
    }
  }

  &.first,
  &:first-child {
    &::after {
      @apply hidden;
    }
  }

  &::after {
    content: '';
    @apply absolute -top-6 left-1/2 -z-10 block h-6 w-[1px] bg-secondary-grey-500;
  }
}
