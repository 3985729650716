.MainPageLearn {
  @apply relative flex h-full w-full flex-col items-start justify-between gap-2 rounded-2xl border border-secondary-grey-500 bg-secondary-grey-200 p-2 lg:justify-center lg:gap-[27px] lg:px-10 lg:py-8;
  @apply self-stretch text-sm font-semibold text-primary-purple-blue-900 lg:text-xl lg:font-medium;

  &__content {
    @apply z-10 flex flex-col items-start self-stretch lg:gap-2;

    &.hasIcon {
      @apply lg:pr-[40%] lg-max:pr-28;
    }

    h4 {
      @apply self-stretch text-[32px] font-bold text-primary-purple-blue-500 lg-max:text-base;
    }
  }

  .icon {
    @apply absolute right-5 h-[100px] lg:right-10 lg:h-4/5;
  }
}
