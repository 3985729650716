.LandingPage {
  @apply bg-secondary-grey-300;

  * {
    @apply font-montserrat;
  }

  h2 {
    @apply mb-4 font-semibold;
    font-size: 24px;
    line-height: 29px;
    &.big {
      @apply font-extrabold;
      letter-spacing: 0.01em;
    }
    &.center {
      @apply text-center;
    }
  }

  &__nav {
    @apply sticky top-0 z-30 bg-white;

    &-wrapper {
      @apply flex items-center justify-between;
      padding: 14px 21px;
    }
  }

  &__container {
    @apply px-5;
    max-width: 768px;
    margin: 0 auto;
  }

  &__btn {
    @apply bg-primary-purple-blue-500 text-center font-semibold text-white transition-colors duration-200;

    &:hover {
      @apply bg-primary-purple-blue-400;
    }

    &:focus,
    &:active {
      @apply inset-1 bg-primary-purple-blue-600;
    }

    &.small {
      padding: 12px;
      font-size: 14px;
      line-height: 18px;
      border-radius: 14px;
      min-width: 110px;
    }

    &.big {
      @apply block w-full;
      padding: 33px 20px;
      border-radius: 22px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__hero {
    @apply my-5;

    &-text {
      @apply pl-1;

      p {
        font-size: 14px;
        span {
          @apply font-bold;
        }
      }
    }

    &-video {
      @apply my-6 flex flex-col items-center justify-center;

      &__wrapper {
        @apply relative;
      }

      img {
        @apply relative z-10;
        max-height: calc(100vh - 200px);
        max-height: calc(100dvh - 200px);
        touch-action: none;
        user-select: none;
        pointer-events: none;
      }

      &__iframe-wrapper {
        @apply absolute left-0 top-0 h-full w-full py-12;
        padding: 8% 5.4%;
      }

      iframe {
        @apply h-full w-full;
      }
    }
  }

  &__info {
    @apply flex flex-col;
    row-gap: 22px;
    margin-bottom: 22px;

    &-card {
      @apply border border-primary-purple-blue-100;
      padding: 32px 10px;
      border-radius: 30px;
      background-image: linear-gradient(to bottom, white, #e9e3ff);

      ul {
        @apply my-8 flex flex-col gap-5;
        padding: 0 9px;
        li {
          @apply relative flex items-center justify-start font-semibold text-secondary-grey-800;
          font-size: 14px;
          line-height: 18px;
          column-gap: 18px;
          padding-left: 6px;

          svg {
            @apply shrink-0 text-primary-purple-blue-500;
          }
        }
      }

      a {
        @apply block w-full;
        span {
          @apply font-bold;
        }
      }
    }
  }

  &__review {
    @apply flex flex-col;
    row-gap: 22px;

    &-videos,
    &-cards {
      @apply flex flex-nowrap overflow-x-scroll px-5;
      column-gap: 10px;
      margin: 0 -20px;
    }

    &-video {
      @apply block shrink-0 rounded-lg bg-primary-purple-blue-300;
      height: 550px;
      max-height: 550px;
      border-radius: 8px;
      visibility: unset;
    }

    &-card {
      @apply shrink-0  bg-primary-purple-blue-500 p-2 text-white;
      font-size: 14px;
      line-height: 18px;
      border-radius: 16px;
      width: 330px;
      max-width: 80%;

      &__profile {
        @apply mb-2 flex items-center justify-start;
      }

      &__avatar {
        @apply mr-3 rounded-full;
        width: 34px;
        height: 34px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &__name {
        @apply font-bold;
      }

      &__text {
        @apply font-medium;
      }
    }
  }

  &__socials {
    @apply flex flex-col;
    row-gap: 10px;
    margin-bottom: 50px;

    &-sub {
      @apply relative bg-white;
      margin-top: 42px;
      padding: 26px 18px 36px 18px;
      border-radius: 30px;

      h2 {
        padding-right: 108px;
      }

      p {
        @apply font-normal text-secondary-grey-800;
        font-size: 12px;
        line-height: 15px;
        margin-top: 18px;
        padding-right: 40px;
        max-width: 450px;
      }

      svg {
        @apply absolute;
        width: 110px;
        height: 110px;
        transform: rotate(11deg);
        top: -38px;
        right: -7px;
      }
    }

    &-embeds {
      @apply flex flex-nowrap overflow-x-scroll px-5;
      margin: 0 -20px;
      column-gap: 10px;
    }

    &-embed {
      @apply block shrink-0 rounded-lg bg-primary-purple-blue-500;
      height: 384px;
      max-height: 384px;
      border-radius: 8px;
      visibility: unset;
    }

    &-cards {
      @apply flex;
      column-gap: 10px;
    }

    &-card {
      @apply flex flex-1 flex-col items-center justify-center bg-white text-center transition-colors;
      border-radius: 30px;
      height: 110px;

      svg {
        @apply text-primary-purple-blue-500;
      }

      span {
        @apply mt-2 font-medium text-black;
        font-size: 12px;
        line-height: 15px;
      }

      &:hover,
      &:active,
      &:focus {
        svg,
        span {
          @apply text-white;
        }
      }

      &:hover {
        @apply bg-primary-purple-blue-500;
      }

      &:active,
      &:focus {
        @apply bg-primary-purple-blue-600;
      }
    }
  }

  &__footer {
    @apply bg-primary-purple-blue-500;
    min-height: 110px;
  }

  &__no-scrollbar {
    @media (pointer: coarse) {
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    @media (pointer: fine) {
      padding-bottom: 10px;
    }
  }
}
