.MainPageEarn {
  @apply relative flex h-full w-full flex-col items-start justify-between gap-2 rounded-2xl border bg-primary-primary bg-cover p-2 lg:justify-center lg:gap-[27px] lg:p-10;
  @apply self-stretch overflow-hidden text-sm font-semibold text-white lg:text-xl lg:font-medium;

  &__content {
    @apply z-10 flex flex-col items-start self-stretch lg:gap-2;

    &.hasIcon {
      @apply lg:pr-[55%] lg-max:pr-32;
    }

    h4 {
      @apply self-stretch text-base font-bold lg:text-4xl;
    }
  }

  .icon {
    @apply absolute right-0 top-0 h-full;
  }

  button {
    @apply z-10 lg:ml-auto;
  }
}
