.Cookie {
  @apply fixed bottom-[70px] z-50 w-full font-normal text-primary-purple-blue-100 md:bottom-0 md:text-lg;
  @apply flex flex-col items-start justify-between gap-4 bg-primary-purple-blue-500 p-4 backdrop-filter sm:flex-row sm:gap-6 md:p-6 md:pr-28;

  b {
    @apply self-stretch text-base font-semibold underline;
  }

  .LandingPage + & {
    @apply bottom-0;
  }
}
