.ProfileReferal {
  @apply relative flex w-full flex-col items-start justify-between gap-6 rounded-[22px] bg-primary-primary bg-cover p-3 lg:rounded-3xl lg:p-6;
  @apply self-stretch text-sm font-semibold text-white lg:text-lg;

  &__content {
    @apply z-10 flex flex-col items-start gap-2 self-stretch;

    span {
      @apply self-stretch text-xs font-medium text-secondary-grey-500 lg:text-base;
    }
  }

  &__btn-text {
    @apply text-wrap text-xs;
    @media screen and (min-width: 352px) {
      @apply text-sm;
    }
    @media screen and (min-width: 390px) {
      @apply text-base;
    }
  }

  .icon {
    @apply absolute right-2 h-4/5 lg:right-10;
  }
}
