.NFTView {
  @apply mb-4 flex flex-col gap-3 lg:mb-5 lg:flex-row lg:gap-5;

  h1 {
    @apply self-stretch text-4xl font-bold text-black-10;
  }

  &__img_wrap {
    @apply flex justify-center lg:block;
  }

  &__img {
    @apply h-full max-h-[180px] w-full max-w-[180px] p-0 lg:max-h-[400px] lg:max-w-[400px] lg:p-0;
  }

  &__school {
    @apply col-span-1 flex w-full flex-col gap-4 lg:col-span-5 lg:gap-5;
  }

  &__purchase {
    @apply flex flex-col items-end gap-4 rounded-3xl bg-white p-4 text-secondary-dark-grey-500 shadow-md shadow-shadow-700 lg:flex-row lg:p-6;
    div {
      @apply flex w-full flex-[1_0_0] flex-col items-start gap-3 self-end text-base font-medium;

      h4 {
        @apply self-stretch text-base font-bold text-black-10 lg:text-lg;
      }

      span {
        @apply text-center text-base font-semibold text-secondary-dark-grey-500;
      }
    }
  }

  &__tags {
    @apply flex flex-col gap-4 rounded-2xl bg-primary-white p-4 shadow-md shadow-shadow-700 lg:rounded-3xl lg:p-6;

    h4 {
      @apply self-stretch text-sm font-semibold text-secondary-dark-grey-900 lg:text-base lg:text-secondary-dark-grey-600;
    }
  }

  &__list {
    @apply flex flex-col items-start justify-center gap-4 self-stretch rounded-3xl bg-white p-4 shadow-md shadow-shadow-700;
  }
}
