.FeedList {
  @apply flex flex-col gap-2 md:gap-6;

  &__content {
    @apply grid grid-cols-1 gap-2 md:grid-cols-10 md:gap-5;
  }
  &__list {
    @apply col-span-1 flex flex-col gap-2 md:col-span-6 md:gap-5 xl:col-span-7;
  }
  &__aside {
    @apply col-span-1 flex flex-none flex-col items-start gap-2 md:col-span-4 md:gap-5 xl:col-span-3;
  }
}
