.AltmetricBar {
  @apply flex flex-col items-start gap-1;

  &__head {
    @apply flex w-full items-start justify-between;

    span {
      @apply text-xs font-semibold text-secondary-dark-grey-700;
    }
  }
}
