.b-quill {
  .ql-toolbar.ql-snow {
    border-color: #e0e5f2;
    border-width: 2px;

    & + .ql-container.ql-snow {
      @apply rounded-t-none;
    }

    .ql-formats {
      margin: 0;
    }
  }

  .ql-container {
    @apply p-0;

    &.ql-snow {
      border-color: #e0e5f2;
      border-width: 2px;
      @apply rounded-2xl;
    }
  }

  &__error {
    .ql-toolbar.ql-snow {
      border-color: #e31a1a;
    }

    .ql-container.ql-snow {
      border-color: #e31a1a;
    }
  }
}

.ql-undo {
  background: no-repeat scroll 50% 50% transparent !important;
  background-image: url('../../assets/icons/quill/undo.svg') !important;
  text-align: center;
}

.ql-undo:hover {
  background-image: url('../../assets/icons/quill/undo.hover.svg') !important;
}

.ql-redo {
  background: no-repeat scroll 50% 50% transparent !important;
  background-image: url('../../assets/icons/quill/redo.svg') !important;
  text-align: center;
}

.ql-redo:hover {
  background-image: url('../../assets/icons/quill/redo.hover.svg') !important;
}

.ql-bold {
  background: no-repeat scroll 50% 50% transparent !important;
  background-image: url('../../assets/icons/quill/bold.svg') !important;
  text-align: center;
}

.ql-bold:hover {
  background-image: url('../../assets/icons/quill/bold.hover.svg') !important;
}

.ql-italic {
  background: no-repeat scroll 50% 50% transparent !important;
  background-image: url('../../assets/icons/quill/italic.svg') !important;
  text-align: center;
}

.ql-italic:hover {
  background-image: url('../../assets/icons/quill/italic.hover.svg') !important;
}

.ql-underline {
  background: no-repeat scroll 50% 50% transparent !important;
  background-image: url('../../assets/icons/quill/underline.svg') !important;
  text-align: center;
}

.ql-underline:hover {
  background-image: url('../../assets/icons/quill/underline.hover.svg') !important;
}

[value='ordered'] {
  background: no-repeat scroll 50% 50% transparent !important;
  background-image: url('../../assets/icons/quill/bullet.svg') !important;
  text-align: center;
}

[value='ordered']:hover {
  background-image: url('../../assets/icons/quill/bullet.hover.svg') !important;
}

[value='bullet'] {
  background: no-repeat scroll 50% 50% transparent !important;
  background-image: url('../../assets/icons/quill/ordered.svg') !important;
  text-align: center;
}

[value='bullet']:hover {
  background-image: url('../../assets/icons/quill/ordered.hover.svg') !important;
}

.ql-image {
  background: no-repeat scroll 50% 50% transparent !important;
  background-image: url('../../assets/icons/quill/image.svg') !important;
  text-align: center;
}

.ql-image:hover {
  background-image: url('../../assets/icons/quill/image.hover.svg') !important;
}

.ql-link {
  background: no-repeat scroll 50% 50% transparent !important;
  background-image: url('../../assets/icons/quill/link.svg') !important;
  text-align: center;
}

.ql-link:hover {
  background-image: url('../../assets/icons/quill/link.hover.svg') !important;
}

.ql-clean {
  background: no-repeat scroll 50% 50% transparent !important;
  background-image: url('../../assets/icons/quill/clean.svg') !important;
  text-align: center;
}

.ql-clean:hover {
  background-image: url('../../assets/icons/quill/clean.hover.svg') !important;
}

.ql-insertTable {
  background-image: url('../../assets/icons/quill/clean.hover.svg') !important;
}
.ql-deleteTable {
  background-image: url('../../assets/icons/quill/clean.hover.svg') !important;
}

.ql-snow .ql-picker.ql-header {
  width: 114px !important;
}

.ql-snow {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  table th,
  table td {
    border: 1px solid #ccc;
    padding: 8px;
  }
}

.ql-editor table {
  border-collapse: collapse;
  width: 100%;
}

.ql-editor td,
.ql-editor th {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.ql-editor [data-list='bullet'],
.ql-editor [data-list='ordered'] {
  margin: 0;
  padding-left: 20px;
  font-size: 16px;
  line-height: 1.6;
}

.ql-editor [data-list='bullet'] {
  list-style-type: disc;
  margin-left: 1em;
}

.ql-editor [data-list='ordered'] {
  list-style-type: decimal;
  margin-left: 1em;
}

.ql-editor [data-list='bullet'] li,
.ql-editor [data-list='ordered'] li {
  margin-bottom: 0.5em;
}

.ql-editor li {
  padding-left: 0px !important;
}

.ql-editor [data-list='bullet'] li::before,
.ql-editor [data-list='ordered'] li::before,
.ql-editor li::before {
  content: none !important;
}

.ql-editor .ql-formula {
  display: inline-block;
  margin: 0 4px;
  font-size: 16px;
  color: #333;
}
