.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.absolute-custom {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
