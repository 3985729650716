.Banners {
  @apply relative w-full overflow-hidden rounded-3xl md:rounded-[30px] md:p-0;
  // @apply h-[213px] md:h-[336px] md:p-0;
  box-shadow: 0px 32px 64px -12px #7090b021;

  &__item {
    @apply h-full w-full;
    img {
      @apply h-full w-full object-contain;
    }
  }
}
