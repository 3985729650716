.DOIModal {
  @apply inline-flex max-w-96 flex-col items-end gap-5;

  &__btns {
    @apply flex items-start justify-between gap-2 self-stretch;
  }

  &__bar {
    @apply flex flex-col gap-3.5;
    @apply self-stretch text-[10px] font-medium text-secondary-dark-grey-500 md:text-xs;

    h6 {
      @apply self-stretch text-center text-sm font-semibold text-primary-purple-blue-500;
    }
  }
}
