@import 'varibles';

.form-control-lg,
.form-select-lg {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  border-color: #f2f2f2;
}

.b-error-input {
  &.form-control:focus,
  &.form-select:focus {
    border-color: red;
    box-shadow: 0 0 0 0.25rem rgba(228, 112, 112, 0.25);
  }

  &.form-control,
  &.form-select {
    border-color: red;
  }
}

.form-check-input {
  border-color: $primary;
  border-width: 2px;
}

.MuiTabs-root button {
  color: $gray-500 !important;
  text-transform: none;
  font-size: 28px;
  font-weight: 600;
}

.MuiTabs-root button.Mui-selected {
  color: $primary !important;
  text-transform: none;
  font-size: 28px;
  font-weight: 600;
}

.MuiTabs-indicator {
  background-color: $primary !important;
  height: 6px !important;
  border-radius: 3px !important;
}

.btn-outline-light {
  color: $black;
}

.btn-padding {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ql-toolbar {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.ql-container {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.b-modal {
  background-color: white;
  position: fixed;
  left: 50%;
  top: 0;
  padding: 20px 25px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 102;

  &__close {
    position: absolute;
    right: 25px;
    top: 20px;
    cursor: pointer;
  }

  &__img {
    margin-top: 50px;
  }

  &__text {
    font-size: 31px;
    font-weight: 600;
    margin-top: 10px;
  }

  &__file {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    left: 0;
  }
}

.form-text {
  color: red;
}

@media screen and (max-width: 992px) {
  .form-check-label {
    font-size: 12px;
  }
}
