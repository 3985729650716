.m-gallery-carousel {
  .carousel {
    .control-dots {
      display: flex;
      justify-content: space-between;
      gap: 4px;
      margin: 0;
      padding: 10px;

      .dot {
        margin: 0;
        width: 100%;
        box-shadow: none !important;
        border-radius: 1px;
        height: 2px;
        background: #ffffffcc;

        .selected {
          background: #ffffff;
        }
      }
    }
  }
}
