.CoursePerfomanceTable {
  @apply flex h-full w-full flex-col gap-4 self-stretch rounded-3xl p-4 md:rounded-[30px] md:p-6;
  @apply bg-white shadow-card shadow-shadow-600;
  @apply text-xs font-medium text-secondary-dark-grey-500 md:text-sm;

  h3 {
    @apply text-xl font-semibold text-secondary-grey-900;
  }

  &__table {
    @apply h-full overflow-x-scroll whitespace-nowrap md:overflow-x-auto;
    th {
      @apply cursor-pointer border-b border-white/0 pb-3 pr-4 text-center md:pb-5;
      @apply text-xs text-secondary-dark-grey-500 md:text-sm;
      &:first-child {
        @apply text-left;
      }
    }
    td {
      @apply min-w-[150px] border-white/0 pb-3 pr-4 text-center md:pb-5;
      &:first-child {
        @apply text-left;
      }
    }
  }
}
