.FeedGallery {
  @apply flex flex-col gap-2 md:gap-4;

  &__images {
    @apply grid gap-2 md:gap-4;
  }

  &__image {
    @apply max-h-[400px] overflow-hidden rounded-lg md:rounded-2xl;

    img {
      @apply block h-full w-full object-cover;
    }
  }

  &__files {
    @apply flex flex-col gap-2 md:gap-4;
  }
}
