.DailyRewardCard {
  @apply flex flex-[1_0_0] flex-col items-center justify-center gap-[4px] border-[1px] bg-primary-white p-2 lg:min-h-[100px] lg:p-2;
  @apply relative gap-[2px] border-[1px] text-xs font-semibold text-secondary-dark-grey-400;

  h3 {
    @apply text-lg font-bold text-secondary-dark-grey-500;
  }

  &.claimed {
    @apply font-bold text-[#00B574];
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;

    h3 {
      @apply text-[#35D28A];
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      text-align: left;
    }
  }

  &.upcoming {
    @apply text-[#8F9BBA];
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;

    h3 {
      @apply text-[#B0BBD5];
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      text-align: left;
    }
  }

  &.can_be_claimed {
    @apply text-[#4318FF];
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;

    &:hover {
      background-color: #ffffff;
    }

    h3 {
      @apply text-[#9374FF];
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      text-align: left;
    }
  }

  @media (min-width: 1024px) {
    &.claimed,
    &.upcoming,
    &.can_be_claimed {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      text-align: left;

      h3 {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        text-align: left;
      }
    }
  }

  &__icon {
    @apply absolute right-2 top-2 fill-primary-purple-blue-500;
  }

  &__image-container {
    width: 33px;
    height: 32px;
  }
}
