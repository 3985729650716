.PutFeedModal {
  @apply flex max-w-full flex-col justify-between gap-3 self-stretch md:max-w-80;
  @apply self-stretch text-center text-sm font-medium;

  h3 {
    @apply flex-[1_0_0] text-lg font-bold text-secondary-grey-900;
  }

  &__btns {
    @apply flex items-center justify-center gap-2 self-stretch;

    button {
      @apply md-max:w-full;
    }
  }
}
