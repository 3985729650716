.FeedReaction {
  @apply flex w-full flex-col gap-2;

  &__buttons {
    @apply flex justify-between gap-2 md:gap-4;

    button {
      @apply w-full;
      svg {
        @apply flex-none fill-primary-purple-blue-500;
      }
      &:hover svg {
        @apply fill-primary-purple-blue-700;
      }
      &.active {
        svg {
          @apply fill-primary-white;
        }
      }
    }
  }

  &__wrapper {
    @apply flex justify-between gap-2;
  }

  &__item {
    @apply flex items-center gap-2 p-2 text-sm font-semibold text-primary-purple-blue-300;

    svg {
      @apply fill-primary-purple-blue-300;
    }
  }
}
