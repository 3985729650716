.QuizHeader {
  @apply flex w-full items-center justify-center gap-2.5 rounded-3xl bg-white p-2 shadow-md shadow-shadow-700 lg:p-3;

  &__item {
    @apply flex flex-[1_0_0] items-center gap-2.5 text-sm font-semibold text-primary-purple-blue-300;
  }

  &__days {
    @apply flex items-center justify-center gap-1.5 rounded-2xl bg-primary-purple-blue-500 p-2;
  }
}
