.QuizTest {
  @apply flex flex-col items-center justify-center gap-4 self-stretch bg-primary-white md:gap-[60px] md:rounded-[30px] md:p-10 md:shadow-md md:shadow-shadow-700;

  &__head {
    @apply flex w-full items-center justify-between gap-6 self-stretch;
    @apply self-stretch text-sm font-semibold text-secondary-dark-grey-500 md:text-base;
    h1 {
      @apply self-stretch text-2xl text-[18px] font-semibold leading-none text-black md:text-3xl;
    }
  }

  &__content {
    @apply grid grid-cols-1 flex-wrap content-start items-start gap-2 self-stretch text-center md:grid-cols-2;

    & > div {
      @apply flex w-full flex-col items-center justify-center gap-1 rounded-[20px] border-2 border-primary-purple-blue-100 bg-primary-purple-blue-100 p-3 md:min-h-[100px] md:p-2;
      @apply text-base font-semibold text-primary-purple-blue-500 md:text-lg md:font-bold;
      @apply cursor-pointer hover:border-primary-purple-blue-300 hover:bg-primary-purple-blue-300 hover:text-primary-purple-blue-100;

      &.error {
        @apply border-primary-red-500 bg-white text-primary-red-500;
      }
      &.success {
        @apply border-primary-green-500 bg-white text-primary-green-700;
      }
      &.selected {
        @apply border-primary-purple-blue-500 bg-primary-purple-blue-500 text-white;
      }
      &.loading {
        @apply border-primary-purple-blue-400 bg-primary-purple-blue-100 text-primary-purple-blue-500;
      }
    }
  }

  &__foot {
    @apply flex flex-col justify-end gap-4 self-stretch md:flex-row md:items-center md:gap-6;
  }

  &__bar {
    @apply flex flex-[1_0_0] items-center gap-3 rounded-2xl bg-secondary-grey-100 px-3;

    &.ending {
      .ProgressBar {
        @apply bg-primary-red-100;
        &__progress {
          @apply bg-primary-red-500;
        }
      }
    }
  }

  &__sec {
    @apply flex min-h-[38px] min-w-[38px] items-center justify-center;
    @apply text-2xl font-bold text-primary-purple-blue-400;
  }
}
