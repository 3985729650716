.FeedComment {
  @apply z-10 flex items-center gap-2 md:gap-3;

  &__img {
    @apply relative h-[42px] w-[42px] flex-none shrink-0 overflow-hidden rounded-full md:h-[52px] md:w-[52px] md:p-0;
  }

  &__input {
    @apply flex w-full gap-2;
    @apply rounded-[34px] bg-secondary-grey-300 px-3 py-4;

    input {
      @apply h-full w-full bg-transparent px-0 outline-none;
      @apply text-base font-semibold text-secondary-dark-grey-900;
      &:placeholder {
        @apply text-secondary-dark-grey-500;
      }
    }
  }

  button {
    @apply flex h-[42px] w-[42px] flex-none items-center justify-center rounded-full p-0 md:h-[52px] md:w-[52px] md:overflow-hidden;
  }

  &.childe {
    .FeedComment {
      &__img {
        @apply h-[34px] w-[34px] md:h-10 md:w-10;
      }

      &__input {
        @apply rounded-bl-none rounded-tl-none px-2 py-3;
        input {
          @apply text-sm font-medium;
        }
      }
    }

    button {
      @apply md:h-[42px] md:w-[42px] md:overflow-hidden;
    }
  }
}
