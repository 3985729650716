.CommunityCard {
  @apply flex w-full flex-wrap gap-3 text-nowrap rounded-3xl bg-white p-3 shadow-card shadow-shadow-700 md:flex-col md:gap-4 md:p-4;

  &__head {
    @apply flex flex-[1_0_0] items-start gap-3 md:gap-4;

    img {
      @apply h-[40px] w-[40px] rounded-[35px] md:h-[64px] md:w-[64px] md:p-0;
    }

    h4 {
      @apply w-full overflow-hidden overflow-ellipsis text-sm font-bold text-secondary-grey-900 md:text-lg;
    }

    span {
      @apply overflow-hidden overflow-ellipsis whitespace-nowrap text-xs font-medium text-secondary-dark-grey-500 md:text-base;
    }

    & > div {
      @apply flex flex-[1_0_0] flex-col items-start justify-center gap-1 self-stretch overflow-hidden;
    }
  }

  &__buttons {
    @apply ml-auto flex justify-end gap-3;
  }
}
