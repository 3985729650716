.StarRating {
  @apply flex w-max;

  &.sm {
    svg {
      @apply h-4 w-4;
    }
  }
  &.md {
    svg {
      @apply h-5 w-5;
    }
  }

  span {
    @apply px-1 first:pl-0 last:pr-0;
  }
}
