.FeedView {
  @apply flex flex-col gap-2 md:gap-6;

  h1 {
    @apply text-lg font-bold text-secondary-dark-grey-900;
  }

  &__content {
    @apply grid grid-cols-1 gap-2 md:grid-cols-10 md:gap-5;
  }
  &__list {
    @apply col-span-1 flex flex-col gap-2 md:col-span-6 md:gap-5 xl:col-span-7;

    h3 {
      @apply text-base font-bold text-secondary-dark-grey-900;
    }

    a {
      @apply flex-[1_0_0] text-sm font-medium underline  hover:no-underline;
    }
  }
  &__aside {
    @apply col-span-1 flex flex-none flex-col items-start gap-2 md:col-span-4 md:gap-5 xl:col-span-3;
  }

  &__wrapper {
    @apply flex flex-col gap-4;
  }

  &__body {
    @apply relative break-words text-base font-medium text-secondary-dark-grey-900;
    ul {
      @apply mb-3 list-disc pl-10;
    }
    ol {
      @apply mb-3 list-decimal pl-10;
    }
    p {
      @apply mb-3 indent-5;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply mb-3 font-bold;
    }
  }

  &__card {
    @apply flex gap-3 md:flex-col;
  }

  &__comments {
    @apply flex flex-col gap-2 md:gap-4;
    h3 {
      @apply text-base font-medium text-secondary-dark-grey-900 md:text-lg;
    }
  }
}
