.CoursePerfomanceProfit {
  @apply flex w-full flex-col gap-4 self-stretch rounded-3xl p-4 md:rounded-[30px] md:p-6;
  @apply bg-white shadow-card shadow-shadow-600;
  @apply text-[10px] font-medium text-secondary-dark-grey-500 md:text-xs;

  h3 {
    @apply text-base font-semibold text-secondary-grey-900 md:text-lg;
  }

  &__head {
    @apply flex flex-col text-center;
  }

  &__foot {
    @apply flex justify-between gap-6 rounded-2xl px-6 py-[4.5px];
    @apply bg-white shadow-card shadow-shadow-600;
  }

  &__card {
    @apply flex w-full flex-col;
  }
}
