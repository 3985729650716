.SupportMore {
  @apply relative flex w-full flex-col items-start justify-center gap-[18px] rounded-2xl border border-secondary-grey-500 bg-secondary-grey-200 p-3 lg:gap-[27px] lg:px-10 lg:py-8;
  @apply self-stretch text-sm font-semibold text-primary-purple-blue-900 lg:text-xl lg:font-medium;

  &__content {
    @apply z-10 flex flex-col items-start gap-2 self-stretch;

    &.hasIcon {
      @apply lg:pr-[40%] lg-max:pr-24;
    }

    h4 {
      @apply self-stretch text-[32px] font-bold text-secondary-grey-900 lg-max:text-base;
    }

    span {
      @apply font-semibold text-primary-purple-blue-400;
    }
  }

  .icon {
    @apply absolute right-2 h-4/5 lg:right-10;
  }
}
