.PurchaseSchoolCard {
  @apply flex flex-col items-start gap-4 self-stretch rounded-3xl bg-white p-0 shadow-md shadow-shadow-700 md:p-4;
  @apply text-xs font-medium md:text-sm;

  &__head {
    @apply flex items-center justify-between gap-2 self-stretch md:gap-2.5;

    h3 {
      @apply flex-[1_0_0] cursor-pointer text-sm font-bold text-primary-purple-blue-600 md:text-lg;
    }

    &.open {
      h3 {
        @apply text-black-10 sm-max:hidden;
      }

      button {
        @apply sm-max:hidden;
      }
    }
  }

  &__content {
    @apply z-0 flex flex-col gap-3 self-stretch overflow-hidden p-0 md:gap-4;
  }
}
