.FeedCommentCard {
  @apply relative flex flex-col gap-4;

  &::before {
    content: '';
    @apply absolute bottom-0 left-[41px] top-0 h-full w-px bg-primary-purple-blue-100 md:left-[103px] md:p-0;
  }

  &__wrapper {
    @apply z-10 flex w-full justify-between gap-3 rounded-2xl border border-primary-purple-blue-100 bg-white p-2;
  }

  &__img {
    @apply h-10 w-10 flex-none overflow-hidden rounded-full;
    img {
      @apply h-full w-full object-cover;
    }
  }

  &__card {
    @apply flex w-full flex-col gap-2;
  }

  &__user {
    @apply flex flex-col gap-[2px] p-0;

    h5 {
      @apply text-base font-bold text-secondary-grey-900;
    }

    span {
      @apply text-xs font-medium text-secondary-dark-grey-500;
    }
  }

  &__content {
    @apply break-all text-sm font-medium text-secondary-grey-900;
  }

  &__links {
    @apply flex cursor-pointer gap-4 text-sm font-semibold text-primary-purple-blue-500;
  }

  &__form {
    @apply flex flex-col gap-4 md:pl-[52px] md:pr-0;
  }
  &__children {
    @apply flex flex-col gap-4 md:pl-[52px] md:pr-0;

    div {
      @apply pl-0;
      &:before {
        @apply hidden;
      }
    }
  }
}
