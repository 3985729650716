.ActionBar {
  @apply absolute inline-flex flex-col items-start gap-1 rounded-2xl bg-white p-2 md:gap-2 md:rounded-3xl md:p-4;
  @apply right-0 z-10 min-w-80 shadow-xl shadow-shadow-700;

  &__item {
    @apply relative flex items-center gap-2 overflow-hidden rounded-2xl p-1 hover:bg-secondary-grey-100;
    @apply w-full cursor-pointer;

    &:hover {
      h6 {
        @apply text-primary-purple-blue-600;
      }
    }
  }

  &__icon {
    @apply flex items-center justify-center gap-1.5 rounded-2xl bg-white p-2 shadow shadow-shadow-600 md:gap-2 md:p-3;
  }

  &__content {
    @apply flex flex-col gap-1;
    h6 {
      @apply text-xs font-medium text-secondary-dark-grey-800 hover:text-primary-purple-blue-600 md:text-sm;
    }
    span {
      @apply text-[10px] font-medium text-secondary-dark-grey-500 md:font-semibold;
    }
  }
}
