.FeedThumb {
  @apply relative max-h-[550px] overflow-hidden rounded-2xl md:rounded-3xl;

  img {
    @apply object-cover;
  }

  &__content {
    @apply absolute bottom-0 left-0 w-full;
    @apply flex flex-col gap-0.5 bg-primary-white bg-opacity-80 p-2 md:gap-2 md:p-4;
    @apply text-sm font-medium text-secondary-dark-grey-600;

    h3 {
      @apply text-base font-bold text-secondary-dark-grey-900 md:text-lg;
    }
  }
}
