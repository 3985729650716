.Services {
  @apply grid flex-[1_0_0] cursor-pointer grid-cols-1 gap-4 self-stretch text-left text-xs font-medium text-secondary-dark-grey-500 md:grid-cols-2 xl:grid-cols-4;

  &__item {
    @apply flex items-center gap-2 self-stretch rounded-2xl bg-white p-2 shadow-md shadow-shadow-700;
  }
  &__icon {
    @apply flex items-center justify-center gap-2 rounded-2xl bg-white p-4 shadow-md shadow-shadow-700;
  }
  h4 {
    @apply text-sm font-semibold text-secondary-dark-grey-900;
  }
}
