.InfoUser {
  @apply flex flex-col gap-2 lg:gap-6 lg-max:-mt-7;
  @apply text-xs font-normal text-secondary-dark-grey-500 lg:text-base;
  h3 {
    @apply text-lg font-semibold text-secondary-grey-900 lg:text-xl;
  }

  &__content {
    @apply grid w-full grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-5;
  }

  &__card {
    @apply gap-0 p-3 shadow-card shadow-shadow-700 lg:p-6;
  }

  &__col {
    @apply grid grid-cols-1 gap-2 lg:gap-5;
  }
}
