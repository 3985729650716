.CourseFeedback {
  @apply flex flex-col gap-2 rounded-[20px] bg-primary-white p-6 md:gap-4;
  box-shadow: 4px 4px 24px 2px #7090b014;

  &__head {
    @apply flex flex-row justify-between gap-3;
    h3 {
      @apply text-base font-bold text-secondary-dark-grey-900 md:text-xl;
    }
  }

  &__btn {
    @apply flex items-start justify-end;
  }
}
