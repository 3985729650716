.MyWallet {
  @apply max-w-5xl md-max:p-0;
  &__content {
    @apply mt-4 w-full rounded-lg p-0 pb-[60px] lg:rounded-[20px] lg:p-5;
  }
  &__wallet {
    @apply relative m-0 overflow-hidden rounded-xl p-4 pb-12 text-white md:rounded-3xl md:py-8 md:pb-14 lg:pb-20 lg:pt-16;
    background: linear-gradient(135deg, #868cff 0%, #4318ff 100%);
    &-cards {
      @apply md:grid md:grid-cols-2 md-max:flex md-max:gap-4;
      & > div {
        @apply border-[#FFFFFF4D] odd:border-r md-max:border-none;
        @apply p-0 md:px-8 xl:pl-12 xl:pr-28;
      }
      &:after {
        content: '';
        @apply absolute -bottom-24 -right-24 block h-80 w-80 rounded-full;
        background: #ffffff1f;
      }
    }
  }
  &__wrapper {
    @apply relative mx-[14px] -mt-6 rounded-2xl bg-white py-4 md:mx-10 md:rounded-[30px] md:py-6 lg:-mt-8;
    box-shadow: 0px 32px 64px -12px #7090b021;
  }
  &__table {
    @apply grid md:grid-cols-2;
    & > div {
      @apply w-full border-secondary-grey-500 px-4 last:border-none md:border-r md:px-6;
    }
  }

  &__label {
    @apply mb-4 flex w-fit items-center gap-2 rounded-full bg-white p-1 pl-2 text-sm font-semibold text-secondary-dark-grey-900 md:mb-5;
    @apply border-[1.5px] border-solid border-white;
    &.active {
      @apply md-max:bg-opacity-0 md-max:text-white;
    }
  }
  &__img {
    @apply h-5 w-5;
  }
  h5 {
    @apply text-base font-semibold text-secondary-grey-900 md:text-lg;
  }
}
