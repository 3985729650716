.RewardModal {
  @apply flex max-w-2xl flex-col items-center justify-center gap-2 rounded-3xl bg-primary-white px-2 py-0 lg:gap-6 lg:px-12 lg:py-0;
  @apply text-center text-sm font-medium text-secondary-grey-900 lg:text-xl lg:font-semibold;

  h3 {
    @apply text-center text-lg font-bold text-primary-purple-blue-600 lg:text-3xl;
  }

  & > img {
    @apply w-1/2 lg:w-auto;
  }
}
