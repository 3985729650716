.MyProfile {
  @apply m-auto flex flex-col gap-2 xl:container lg:gap-6;

  &__content {
    @apply w-full gap-2 lg:flex lg:gap-5;
  }

  &__head {
    @apply hidden flex-col justify-between gap-4 rounded-[22px] lg:col-span-2 lg:flex lg:flex-row lg:gap-5 lg:rounded-[30px] lg:p-5 lg:shadow-lg lg:shadow-shadow-700;
    @apply text-xs font-normal text-secondary-dark-grey-500 lg:text-base;
  }

  &__btns {
    @apply flex justify-between gap-2 lg:gap-4;
    button {
      @apply w-full;
    }
  }

  &__nav {
    @apply flex w-full justify-between gap-2 overflow-x-auto lg:hidden;
    button {
      @apply w-full;
    }
  }

  &__card {
    @apply flex flex-col justify-between gap-4 p-3 shadow-card shadow-shadow-700 lg:gap-5 lg:p-6;
    @apply text-xs font-normal text-secondary-dark-grey-500 lg:text-base;
  }

  h2 {
    @apply text-lg font-semibold text-secondary-grey-900;
  }
  h3 {
    @apply text-base font-semibold text-secondary-grey-900 lg:text-2xl;
    span {
      @apply text-secondary-dark-grey-500;
    }
  }
  h6 {
    @apply text-sm font-semibold text-secondary-grey-900 lg:text-lg;
  }

  &__count {
    @apply flex justify-evenly text-xs font-medium text-secondary-dark-grey-500 lg:text-sm lg:font-normal;
    div {
      @apply flex flex-col items-center gap-1;
      h4 {
        @apply text-base font-semibold text-secondary-grey-900 lg:text-xl;
      }
    }
  }

  &__profile {
    @apply flex items-center gap-2 text-xs text-secondary-dark-grey-500;
    img {
      @apply h-10 w-10 rounded-full;
    }
  }
}
