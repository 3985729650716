.CourseComment {
  @apply flex flex-col gap-2 rounded-[20px] bg-secondary-grey-100 p-2 md:gap-4 md:p-6;
  &.mine {
    @apply bg-primary-white shadow-md shadow-shadow-700;
  }
  &__user {
    @apply flex items-center gap-3 overflow-hidden text-nowrap;
    h4 {
      @apply overflow-hidden text-ellipsis text-base font-bold text-secondary-dark-grey-900;
    }
    span {
      @apply text-sm text-secondary-dark-grey-600;
    }
    div {
      @apply flex flex-col overflow-hidden;
    }
  }
  &__img {
    @apply h-[34px] w-[34px] flex-none rounded-full md:h-10 md:w-10;
  }
  &__comment {
    @apply max-h-[70px] overflow-hidden text-ellipsis break-words text-base font-medium text-secondary-dark-grey-900;
    &.expanded {
      @apply max-h-none;
    }
  }
  &__rating {
    @apply flex items-center gap-2 text-base font-bold leading-5 text-primary-orange-500;
    span {
      @apply mt-1;
    }
  }
}
