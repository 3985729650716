.ProgressBar {
  @apply w-full rounded-full bg-secondary-grey-300 dark:bg-secondary-dark-grey-700;
  &.sm {
    @apply h-1;
  }
  &.md {
    @apply h-2;
  }
  &.lg {
    @apply h-4;
  }
  &.xl {
    @apply h-6;
  }

  &__progress {
    @apply h-full rounded-full bg-primary-purple-blue-500;
  }
}
