.ProfileCreateCourse {
  @apply relative flex w-full flex-col items-start justify-between gap-2 rounded-[22px] bg-primary-primary bg-cover p-3 lg:rounded-3xl lg:p-4;
  @apply self-stretch text-sm font-semibold text-white lg:text-lg;

  &__content {
    @apply z-10 flex flex-col items-start gap-1 self-stretch;

    span {
      @apply self-stretch text-xs font-medium text-secondary-grey-500 lg:text-base;
    }
  }

  .icon {
    @apply absolute right-2 h-4/5 lg:right-10;
  }
}
