$border-radius-lg: 12px;
$border-radius: 12px;

$primary: #6a4ff5;
$black: #1a1c1e;
$light: #f2f2f2;

$gray-75: #fafafa;
$gray-200: #f4f4f4;
$gray-300: #f2f2f2;
$gray-500: #949494;

$green-300: #52a31d;
$green-50: #eef6e8;

$red-300: #d91f11;

$yellow-50: #fbf6e8;
$yellow-300: #d9a514;

$blue-50: #e8f1ff;
$blue-300: #0250ec;

$primary-200: #836df7;

$neutral-50: #f7f8f9;
$neutral-500: #64748b;
$neutral-600: #4b5768;
$neutral-800: #191d23;

$border-color: #a3aed0;
