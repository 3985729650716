.b-multi-base {
  appearance: none; /* Убрать кастомные стрелки на iOS */
  background: none;
  outline: none !important;
  box-shadow: none !important;
  &.disabled [class*='-control'] {
    background-color: #f6f8fd !important;
  }

  @apply flex items-stretch;

  [class*='-control'] {
    border: none;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 16px;
    width: 100%;
    background-color: transparent;

    @apply flex items-baseline;

    & > div {
      @apply flex items-baseline;
      padding: 5px 8px !important;
      & + div {
        padding: 0 !important;
      }
    }
  }

  [class*='-placeholder'] {
    color: #1b2559;
  }

  [class*='-multiValue'] {
    background-color: #e9e3ff;
    border-radius: 9999px;
    overflow: hidden;

    [class*='-MultiValueGeneric'] {
      color: #4318ff;
      font-weight: 600;
    }

    [class*='-MultiValueRemove'] {
      color: #4318ff;

      &:hover {
        background-color: transparent;
      }
    }
  }

  span {
    display: none;
  }
}

.react-select__control {
  border-radius: 12px;
  border: 2px solid #ccc;
  min-height: 42px;

  &:hover {
    border-color: #aaa;
  }
}

.react-select__value-container {
  padding: 0 8px;
}

.react-select__single-value {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.react-select__placeholder {
  color: #888;
}

.react-select__menu {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.react-select__option {
  padding: 8px 12px;
  font-size: 14px;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator {
  color: #888;
}
