$thumb-size: 22px;

@mixin track-styles {
  @apply appearance-none border-transparent bg-transparent;
}

@mixin thumb-styles {
  @apply pointer-events-auto cursor-grab appearance-none rounded-none border-0 border-none;
  width: $thumb-size;
  height: $thumb-size;
  background-color: red;

  &:active {
    cursor: grabbing;
  }
}

.SliderBar {
  @apply relative flex w-full items-center;

  &__input-wrapper {
    position: absolute;
    width: calc(100% + #{$thumb-size});
    margin: 0 calc(#{$thumb-size} / -2);
    height: $thumb-size;
  }

  &__control-wrapper {
    @apply absolute w-full;
    height: $thumb-size;
  }

  &__input {
    @apply pointer-events-none absolute z-20 h-full w-full appearance-none p-0 opacity-0;

    &::-ms-track {
      @include track-styles;
    }

    &::-moz-range-track {
      @include track-styles;
    }

    &:focus::-webkit-slider-runnable-track {
      @include track-styles;
    }

    &::-ms-thumb {
      @include thumb-styles;
    }

    &::-moz-range-thumb {
      @include thumb-styles;
    }

    &::-webkit-slider-thumb {
      @include thumb-styles;
    }
  }

  &__rail {
    @apply absolute top-[50%] h-2 w-full rounded-full bg-secondary-grey-500;
    transform: translateY(-50%);
  }

  &__inner-rail {
    @apply absolute h-full bg-primary-purple-blue-500;
  }

  &__control {
    @apply absolute top-[50%] z-10 rounded-full border-4 border-solid border-white bg-primary-purple-blue-500;
    width: $thumb-size;
    height: $thumb-size;
    margin-left: calc(#{$thumb-size} / -2);
    transform: translate3d(0, -50%, 0);
    box-shadow: 0px 3px 8px 0px #7090b033;
  }

  &__range {
    @apply -mx-2 mt-12 flex w-[105%] justify-between text-[10px] font-semibold sm:gap-2;
    span {
      @apply flex items-center justify-center sm:p-0;
      width: $thumb-size;
      height: $thumb-size;
    }
  }
}
