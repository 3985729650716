html {
  -webkit-user-select: none;
  user-select: none;
  --safe-area-top-height: env(safe-area-inset-top);
  --safe-area-top-bc: unset;
  --safe-area-bottom-height: env(safe-area-inset-top);
  // tg dark theme default bottom color #151e27
  --safe-area-bottom-bc: #151e27;
}

// rm in future horizon
body {
  background-color: white;
  margin: 0;
  font-family:
    Montserrat,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // margin-right: calc(env(safe-area-inset-right) + var(--tg-safe-area-inset-right)) !important;
  // margin-left: calc(env(safe-area-inset-left) + var(--tg-safe-area-inset-left)) !important;
  overflow-x: hidden !important;
  touch-action: manipulation;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import 'styles/fonts';
@import 'styles/varibles';
@import 'styles/global';
@import 'styles/customs';
@import '../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Montserrat', sans-serif !important;
    font-feature-settings: 'kern' !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.2px;
  }
}

@layer utilities {
  .scrollbar-hide {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.collapse {
  visibility: visible !important;
}

svg {
  display: inline !important;
}

.border-width {
  border-width: 1px;
}

.text-color-white {
  color: white;
}

.chakra-modal__content-container {
  z-index: 100;
}

a {
  @apply text-primary-purple-blue-500;
}

.SafeAreaTop {
  height: var(--safe-area-top-height);
  background-color: var(--safe-area-top-bc);
}

.AddBottom {
  padding-bottom: calc(var(--safe-area-bottom-height) + 0.5rem) !important;
}

.bottom-16-safe {
  bottom: calc(var(--safe-area-bottom-height) + 4rem);
}

@media (min-width: 1100px) {
  .SafeSideBar {
    margin-top: calc(var(--safe-area-top-height) + 100px);
  }
}

// fix for tonconnect modal
.go3680507625 {
  max-height: 320px !important;
  overflow-y: auto;
}
