.StartedCard {
  @apply flex h-full w-full gap-2 rounded-3xl p-2;
  box-shadow: 4px 4px 24px 0px #7090b01f;

  &__img {
    @apply relative flex h-32 w-32 flex-none items-center justify-center overflow-hidden rounded-3xl bg-secondary-grey-400 md:h-36 md:w-36;
    img {
      @apply h-full w-auto;
    }
    &-info {
      @apply absolute bottom-0 left-0 flex h-[50px] w-full flex-col gap-[6px] p-3;
      background: linear-gradient(
        180deg,
        rgba(27, 37, 89, 0) 0%,
        rgba(27, 37, 91, 0.56272) 36.4%,
        rgba(27, 37, 91, 0.6714) 56.4%,
        rgba(29, 38, 86, 0.9) 100%
      );
      span {
        @apply text-xs font-medium text-white;
      }
    }
  }

  &__content {
    @apply flex w-full flex-col justify-between gap-3;
    h4 {
      @apply overflow-hidden text-ellipsis text-xl font-semibold text-black;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    span {
      @apply text-sm font-medium text-secondary-dark-grey-700;
    }
  }

  &__bar {
    @apply flex h-[2px] w-full rounded-full bg-[#FFFFFF80] p-0;
    div {
      @apply h-full rounded-full bg-white;
    }
  }
}
