.Breadcrumbs {
  @apply mb-[2px] text-sm font-medium;

  a {
    @apply relative mr-1 pr-2 text-gray-700;
    &:after {
      content: '/';
      @apply absolute right-0 top-0 block last:hidden;
    }
  }
}
