.WalletTable {
  @apply w-full;
  th,
  td {
    @apply p-2 first:pl-0 first:text-left last:pr-0 last:text-right;
  }

  tr {
    @apply border-b border-solid border-secondary-grey-500;
  }

  th {
    @apply pt-0 text-sm font-medium text-secondary-dark-grey-500;
  }
  td {
    @apply text-balance text-xs font-bold text-secondary-dark-grey-900 md:text-sm;
    overflow-wrap: anywhere;
  }
}
