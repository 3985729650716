@import 'src/styles/varibles';

.b-profile-teacher-my-course-card {
  @apply flex w-full flex-[1_0_0] items-center justify-between gap-2 rounded-[20px] bg-white p-2 shadow-card shadow-shadow-500 md:rounded-3xl;

  &__img {
    @apply flex h-[144px] w-[144px] flex-none flex-col items-start justify-end gap-2.5 rounded-2xl bg-cover md:h-36 md:w-36;
  }

  &__name {
    @apply self-stretch overflow-hidden overflow-ellipsis text-lg font-semibold text-black md:text-xl;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  span {
    @apply self-stretch overflow-hidden overflow-ellipsis text-sm font-medium text-secondary-dark-grey-700;
  }

  &__avtor {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
    color: $gray-500;
  }

  &__badge {
    background-color: $blue-50;
    padding: 6px 8px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
    width: fit-content;
    color: $blue-300;
  }
}
