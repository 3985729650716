.QuizEndModal {
  @apply flex max-w-2xl flex-col items-center justify-center gap-4 rounded-3xl bg-primary-white md:gap-6;
  @apply m-auto text-center text-base font-medium text-secondary-grey-900 md:text-xl md:font-semibold;
  @apply p-0 md:p-16 md:pt-0;

  h3 {
    @apply text-center text-2xl font-bold text-primary-purple-blue-600 md:text-3xl;
  }

  &__content {
    @apply flex w-full flex-col items-center justify-center gap-2 self-stretch;
    @apply text-primary-purple-blue-400;
  }

  img {
    @apply w-3/4;
  }
}
