.Modal {
  &__bg {
    @apply z-50 min-w-80 bg-[#161C5140] p-0;
  }
  &__content {
    @apply left-0 right-0 m-auto flex h-full w-max max-w-fit items-center;
    max-height: calc(
      100vh - 70px - var(--safe-area-bottom-height) - var(--safe-area-top-height)
    );

    &--center {
      justify-content: center !important;
    }

    &--top {
      justify-content: baseline !important;
    }

    &--bottom {
      justify-content: end !important;
    }
  }

  &__header {
    @apply flex items-center justify-between gap-2;
    h3 {
      @apply text-base font-bold text-secondary-grey-900 md:text-lg;
    }

    &--center {
      @apply justify-center;
    }
  }

  &__close {
    @apply relative z-50 ml-auto flex cursor-pointer items-center justify-center rounded-full;
    @apply bg-primary-white text-primary-purple-blue-500 shadow-sm shadow-shadow-600;
  }

  &__body {
    @apply mx-2 flex h-auto flex-col gap-2 overflow-y-auto rounded-2xl bg-white p-2 sm:min-w-96 sm:gap-5 sm:p-5 sm-max:w-[calc(100vw-16px)] md:rounded-[20px] md:p-6;
  }
}

.chakra-modal__content-container {
  height: 100% !important;
}
