.LoaderMini {
  @apply flex w-full items-center justify-center;
}

.spinner {
  @apply h-10 w-10 rounded-full border-4 border-solid border-secondary-dark-grey-300 border-t-primary-purple-blue-500;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
