.EditFeed {
  @apply flex w-full flex-col gap-6;

  &__wrapper {
    @apply flex flex-col gap-4 rounded-[30px] bg-white shadow-card shadow-shadow-700 md:max-w-[850px] md:gap-6;
    @apply mx-auto w-full rounded-[20px] p-2 md:p-6;
  }

  h4 {
    @apply text-lg font-medium text-secondary-dark-grey-900;
    span {
      @apply text-secondary-dark-grey-500;
    }
  }

  &__flex {
    @apply grid gap-4 md:grid-cols-2 md:gap-6;
  }

  &__preview {
    @apply flex flex-col gap-2 md:gap-4;
  }

  &__file {
    @apply relative flex w-full overflow-hidden rounded-3xl md:w-3/4 lg:w-3/5;
    svg {
      @apply absolute right-3.5 top-3.5 fill-primary-purple-blue-500;
      @apply h-[34px] w-[34px] cursor-pointer rounded-full bg-white p-2;
      &:hover {
        @apply bg-primary-purple-blue-500 fill-white;
      }
    }
  }
}
