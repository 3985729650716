@import '../../../styles/varibles';

.b-nft-list {
  .form-control:focus {
    box-shadow: none !important;
    border-color: $gray-300 !important;
  }

  .form-control {
    border-left: 0;
  }

  .input-group-text {
    background-color: transparent;
    padding-right: 0;
  }

  &__title {
    font-size: 32px;
    color: $black;
    font-weight: 600;
    margin-bottom: 0;
  }

  .btn {
    font-weight: 600;
  }

  &__img {
    width: 100%;
    border-radius: 36px;
  }
}

.b-arrow-left {
  transform: rotate(180deg);
}
