.MobileNavBar {
  @apply fixed bottom-0 left-0 right-0 z-50 hidden lg-max:flex flex-col;
}

.MobileNavBarIn {
  @apply w-full items-start justify-between gap-2 bg-white p-2 flex;
}

.SafeAreaBottom {
  height: var(--safe-area-bottom-height);
  background-color: var(--safe-area-bottom-bc);
}
