.DropdownButton {
  @apply absolute inline-flex flex-col items-start gap-1 rounded-2xl bg-white p-2 md:gap-2 md:rounded-3xl md:p-4;
  @apply right-0 z-10 min-w-80 shadow-xl shadow-shadow-700;

  &__item {
    @apply relative flex items-center gap-2 overflow-hidden rounded-2xl p-1 hover:bg-secondary-grey-100;
    @apply w-full text-sm font-medium text-secondary-dark-grey-800 hover:text-primary-purple-blue-600;
    @apply cursor-pointer;

    &-icon {
      @apply flex items-center justify-center gap-1.5 rounded-2xl bg-white p-2 shadow shadow-shadow-600 md:gap-2 md:p-3;
    }

    .icon_right {
      @apply flex-[1_0_0] text-right;

      svg {
        @apply fill-secondary-grey-600;
      }
    }
    &:hover {
      .icon_right {
        svg {
          @apply fill-primary-purple-blue-600;
        }
      }
    }

    &.remove {
      @apply text-red-500 hover:text-red-700;

      svg {
        @apply fill-red-500;
      }
      &:hover {
        .icon_right {
          svg {
            @apply fill-red-700;
          }
        }
      }
    }
  }
}
