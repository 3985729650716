.Toastify__toast-container {
  @apply right-2 lg:right-5 lg:max-w-[422px];
  box-sizing: border-box;
  width: calc(100vw - 16px);
  top: 110px !important;
  left: auto;

  @media (max-width: 1024px) {
    top: 130px !important;
  }
}

.app-toast {
  @media (max-width: 1024px) {
    top: -70px !important;
  }
}

.Toastify__toast {
  color: #2b3674;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 16px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  border-radius: 8px;
  min-height: auto;

  &-body {
    margin: 0 !important;
    padding: 0;
  }
}

.Toastify__close-button {
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #2b3674;
  zoom: 1.2;

  svg {
    path {
      width: 14px;
      height: 14px;
      fill: #2b3674;
    }
  }

  &:hover {
    svg {
      path {
        fill: #2b3674;
      }
    }
  }
}

.Toastify__close-button--light {
  opacity: 1;
}

.Toastify__toast--success {
  background-color: #c9fbd5;

  .Toastify__toast-icon svg {
    fill: #00b574;
  }

  .Toastify__close-button {
    stroke: #c9fbd5;
  }
}

.Toastify__toast--warning {
  background-color: #fff6da;

  .Toastify__toast-icon svg {
    fill: #ffb547;
  }

  .Toastify__close-button {
    stroke: #fff6da;
  }
}

.Toastify__toast--info {
  background-color: #e9e3ff;

  .Toastify__toast-icon svg {
    fill: #4318ff;
  }

  .Toastify__close-button {
    stroke: #e9e3ff;
  }
}

.Toastify__toast--error {
  background-color: #fde0d0;

  .Toastify__toast-icon svg {
    fill: #e31a1a;
  }

  .Toastify__close-button {
    stroke: #fde0d0;
  }
}

.Toastify__progress-bar {
  height: 0px;
}
