.FolderCard {
  @apply flex flex-col items-start gap-2;
  @apply self-stretch overflow-hidden overflow-ellipsis text-sm font-medium text-secondary-dark-grey-700;

  &__img {
    @apply flex h-[298px] w-full flex-wrap items-center justify-center overflow-hidden rounded-3xl bg-secondary-grey-300;
    svg {
      @apply fill-primary-purple-blue-500;
    }
    img {
      @apply h-full w-full object-cover;
    }
  }

  &__head {
    @apply flex flex-col items-start gap-0.5 self-stretch;

    h3 {
      @apply text-lg font-semibold text-secondary-dark-grey-900;
    }
  }
}
