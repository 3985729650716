.Filter {
  @apply right-0 z-20 flex flex-col gap-4 sm:absolute sm:top-11;
  @apply overflow-y-auto sm-max:fixed sm-max:left-0 sm-max:top-[70px] sm-max:h-[calc(100vh_-_130px)] sm-max:p-4;
  @apply rounded-3xl bg-white shadow-sm shadow-shadow-600 sm:px-6 sm:py-4;

  &__close {
    @apply absolute right-6 top-4 p-2 sm-max:hidden;
  }

  &__item {
    @apply flex flex-col gap-2;
    h4 {
      @apply text-sm font-semibold text-secondary-grey-800;
    }
  }
}
