.NotificationCard {
  @apply flex items-start gap-2.5 bg-white;
  @apply text-xs font-medium text-secondary-dark-grey-500;

  &__icon {
    @apply flex items-center justify-center gap-2 rounded-2xl bg-primary-purple-blue-100 p-3;
  }

  &__content {
    @apply flex flex-[1_0_0] flex-col items-start justify-center gap-1;

    h4 {
      @apply self-stretch text-base font-semibold text-secondary-grey-900;
    }

    p {
      @apply text-balance text-sm font-medium text-secondary-dark-grey-500;
      overflow-wrap: anywhere;
    }
  }
}
