.AttachementCard {
  @apply rounded-[30px] border border-secondary-grey-400 p-2 hover:bg-secondary-grey-100 md:border-2 md:p-3;
  @apply flex flex-[1_0_0] items-center justify-between gap-2;
  span {
    @apply w-full truncate text-xs font-medium text-black;
  }
  .icon {
    @apply fill-secondary-dark-grey-500 hover:fill-primary-purple-blue-600;
  }
}
