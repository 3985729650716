.ShareButtons {
  @apply w-full;
  &__socials {
    @apply mb-6 flex items-start justify-around gap-5 lg:gap-7;
  }

  &__social {
    @apply flex flex-shrink-0 flex-col items-center justify-center text-center text-primary-purple-blue-900;
    @apply outline-none;
    &-text {
      @apply mt-1 text-[10px] font-medium leading-3;
    }
    &-icon {
      @apply rounded-full border-2 border-secondary-dark-grey-100 bg-secondary-dark-grey-100 p-2.5 leading-none transition-colors;
      &-svg {
        @apply h-5 w-5;
      }
    }

    $socialBtn: &;
    &:hover:not(:disabled) {
      #{$socialBtn}-icon {
        @apply bg-secondary-dark-grey-200;
      }
    }
    &:active:not(:disabled),
    &:focus:not(:disabled) {
      #{$socialBtn}-icon {
        @apply border-secondary-dark-grey-400;
      }
    }
    &:disabled {
      opacity: 0.6;
    }

    @media screen and (min-width: 400px) {
      &-text {
        @apply mt-2 text-xs;
      }
      &-icon {
        @apply p-3;
      }
    }

    @media screen and (min-width: 768px) {
      &-text {
        @apply text-sm;
      }
      &-icon {
        @apply p-5;
      }
    }
  }

  &__link {
    flex-grow: 1;
    width: 0;
    max-height: 48px;
    &-text {
      @apply max-w-[65%] truncate;
    }
    &-icon {
      @apply h-5 w-5 flex-shrink-0;
    }
  }

  &__tg {
    &-wrapper {
      @apply mb-4 flex flex-col gap-2 rounded-[20px] border border-secondary-dark-grey-200 p-2 sm:p-3;
    }
    &-text {
      @apply text-xs font-semibold text-secondary-dark-grey-500 sm:text-base sm:leading-5;
    }
  }
}
