.FavoriteView {
  @apply flex flex-[1_0_0] flex-col items-start gap-3 self-stretch md:gap-6;
  @apply self-stretch overflow-hidden overflow-ellipsis text-sm font-medium text-secondary-dark-grey-700;

  h3 {
    @apply text-base font-semibold text-secondary-grey-900;
  }

  &__head {
    @apply flex items-start gap-2 self-stretch rounded-2xl p-2 shadow-md shadow-shadow-700 sm-max:flex-col md:gap-4 md:rounded-3xl md:p-4;

    div {
      @apply flex flex-[1_0_0] flex-col items-start gap-2;
    }
  }

  &__wrapper {
    @apply m-auto flex w-full max-w-3xl flex-shrink-0 flex-col items-start gap-2 md:gap-5;
  }
}
