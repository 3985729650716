.MemberCard {
  @apply flex w-full flex-wrap content-center items-center justify-end gap-2 self-stretch rounded-3xl bg-white p-3 shadow-card shadow-shadow-700 md:gap-4 md:p-4;

  &__avatar {
    @apply h-16 w-16 overflow-hidden rounded-full;
    img {
      @apply h-full w-full object-cover;
    }
  }

  &__wrapper {
    @apply flex flex-[1_0_0] items-center gap-3 md:gap-4;
  }

  &__user {
    @apply flex flex-[1_0_0] flex-col justify-center gap-1 self-stretch;
    @apply overflow-hidden overflow-ellipsis text-base font-medium text-secondary-dark-grey-500;

    h4 {
      @apply self-stretch overflow-hidden overflow-ellipsis text-lg font-bold text-secondary-grey-900;
    }
  }
}
