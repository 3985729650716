.subject-avatar-container {
  top: calc(var(--safe-area-top-height) + 4rem);
}

@media (min-width: 1024px) {
  .subject-avatar-container {
    top: calc(var(--safe-area-top-height) + 6.5rem);
    left: calc(50vw - 19rem);
  }
}
