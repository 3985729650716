.CourseAsideCard {
  @apply flex w-full flex-col gap-2 rounded-2xl bg-white p-2 shadow-card shadow-shadow-700 md:rounded-[30px] md:p-5;

  &__head {
    @apply flex flex-none items-start justify-between gap-2;

    h4 {
      @apply text-base font-bold text-secondary-grey-900 md:text-lg;
    }

    button {
      @apply ml-auto flex-none shrink-0;
    }
  }
}
