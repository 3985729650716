.CourseCard {
  @apply flex min-h-44 w-full cursor-pointer flex-col justify-between overflow-hidden rounded-3xl bg-cover bg-center;

  &.default {
    @apply md:min-h-64 xl:h-72;
  }
  &.recomended {
    @apply xl:h-60;
  }

  &__head {
    @apply flex items-start justify-between gap-2 p-2 pb-4 pl-3 text-primary-white;
    background: linear-gradient(
      180deg,
      rgba(29, 38, 86, 0.9) 0%,
      rgba(27, 37, 91, 0.6714) 49.9%,
      rgba(27, 37, 91, 0.56272) 58.9%,
      rgba(27, 37, 89, 0) 100%
    );

    h5 {
      @apply truncate text-sm font-bold;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }

    &-stats {
      @apply flex items-center gap-1 text-xs font-medium;
      span {
        @apply flex gap-1;
        svg {
          @apply block h-[13px] w-[13px] p-0;
          path {
            @apply -mt-1 fill-primary-white;
          }
        }
      }
    }
  }
  &__foot {
    @apply flex flex-row gap-2 p-2;
    button {
      @apply self-end;
    }
    &.default {
      @apply flex-row items-center justify-between md:flex-col md:items-start;
      button {
        @apply w-auto md:w-full;
      }
    }
    &.recomended {
      @apply items-center justify-between md:flex-col md:items-start lg:flex-row lg:items-center;
      button {
        @apply w-auto md:w-full lg:w-auto;
      }
      .Tags {
        div {
          @apply bg-primary-white;
        }
      }
    }
  }
}
