.SubjectCard {
  @apply flex items-start self-stretch;

  &__icon {
    @apply flex h-[100px] w-[100px] flex-col items-start gap-4 rounded-[20px] bg-primary-white p-1 md:h-[154px] md:w-[154px] md:p-2;
    @apply shadow-md shadow-shadow-700;

    img {
      @apply h-full w-full;
    }
  }

  &__wrapper {
    @apply flex flex-[1_0_0] flex-col justify-between gap-3 self-stretch rounded-[20px] bg-primary-white  p-2 md:rounded-[22px] md:p-3;
    @apply text-xs font-semibold text-primary-purple-blue-600 md:text-sm;
    @apply shadow-md shadow-shadow-700;

    h4 {
      @apply text-base font-bold text-secondary-dark-grey-900 md:text-xl;
    }
  }

  &__badges {
    @apply flex flex-wrap gap-0.5 md:gap-2;
  }

  .favorite {
    @apply flex h-[34px] w-[34px] min-w-[34px] cursor-pointer items-center justify-center rounded-2xl shadow-xs shadow-gray-300 lg:h-[42px] lg:w-[42px] lg:min-w-[42px] lg:rounded-2xl;
  }

  .link {
    @apply hidden w-[54px] items-center justify-center rounded-[22px] bg-purple-100 shadow-sm shadow-shadow-700 lg:flex self-stretch;
  }
}
