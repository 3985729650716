.rsbs-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.rsbs-content {
  background-color: rgba(255, 255, 255, 0.9);
}

[data-rsbs-root] {
  pointer-events: auto;
}

[data-rsbs-backdrop] {
  pointer-events: auto;
}

[data-rsbs-overlay] {
  max-height: calc(
    var(--rsbs-overlay-h, 0) - var(--safe-area-bottom-height) - var(
        --safe-area-top-height
      )
  );
  min-height: 67vh;
  margin-bottom: var(--safe-area-bottom-height);
}

[data-rsbs-state='closing'] {
  position: absolute !important;
  top: 0px;
  bottom: 0px;
  height: 100vh;
  width: 100vw;
  visibility: hidden;
  transition:
    opacity 0.1s ease,
    visibility 0.1s ease;

  [data-rsbs-backdrop] {
    pointer-events: none;
    visibility: hidden;
  }
}

.disable-scroll [data-rsbs-scroll] {
  overflow: hidden;
}
