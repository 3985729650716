.DailyRewardModal {
  @apply relative flex w-full flex-col items-center justify-center gap-4 rounded-[30px] bg-white md:w-[700px] md:gap-8 md:p-5;

  &__closeIcon {
    @apply absolute right-0 top-0 h-6 w-6 cursor-pointer;
  }

  &__head {
    @apply flex flex-col items-center justify-center gap-2 self-stretch;
    @apply self-stretch text-center text-sm font-semibold text-secondary-dark-grey-500 md:text-base;
    h1 {
      @apply text-2xl font-semibold text-black md:text-3xl;

      @media (min-width: 320px) and (max-width: 600px) {
        @apply text-xl;
      }
    }
  }

  &__days {
    @apply grid grid-cols-3 items-start self-stretch rounded-[20px] outline outline-2 outline-[#E0E5F2] md:grid-cols-3;
    overflow: hidden;
    box-sizing: border-box;
    div {
      &:last-child {
        border-bottom: none;
        @apply col-span-3 md:col-span-3;
      }
    }
  }

  &__turnstile {
    position: relative;
    z-index: 20;
  }

  &__loadingOverlay {
    @apply absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-white bg-opacity-80;
    z-index: 10;
  }
}
